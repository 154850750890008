import React, { useState, useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import Carousel from 'react-bootstrap/Carousel';

import { ElementBanner, ElementContentLink, ElementFooterLink, ElementHeader, ElementHeaderLarge, ElementPanelContentContain, ElementPanelContentContainIcon, ElementPanelHeader, ElementPanelLabel, ElementStatus } from '../components/Element';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

// Props: debug, ip_address, translations, upload_images
function SectionContent(props)
{
    // Content
    const [content_data, content_set_data] = useState(undefined);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: ['process', 'contact', 'open', 'glaslook']
                };
                
                const response = await axios.get(props.ip_address+'/backend/autoglaslimburg/api/content', {params});
                // <str> content text

                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    // Photos
    const [photos_data, photos_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/autoglaslimburg/api/photos');
                // <str> image

                photos_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let carousel_mobile = [];
    let carousel_desktop = [];

    if (photos_data.length !== 0)
    {
        // Tablet
        for (let i = 0; i < Math.ceil(photos_data.length/2); i++)
        {
            carousel_mobile.push(
                <Carousel.Item className='slide' key={i}>
                    <div className="carousel-image-mobile" style={{backgroundImage: 'url('+props.upload_images+'/'+photos_data[i*2]+')'}}>
                    </div>

                    { (i*2)+1 < photos_data.length &&
                        <div className="carousel-image-mobile" style={{backgroundImage: 'url('+props.upload_images+'/'+photos_data[(i*2)+1]+')'}}>
                        </div>
                    }
                </Carousel.Item>
            );
        }

        // Desktop
        for (let i = 0; i < Math.ceil(photos_data.length/3); i++)
        {
            carousel_desktop.push(
                <Carousel.Item className='slide' key={i}>
                    <div className="carousel-image-desktop" style={{backgroundImage: 'url('+props.upload_images+'/'+photos_data[i*3]+')'}}>
                    </div>

                    { (i*3)+1 < photos_data.length &&
                        <div className="carousel-image-desktop" style={{backgroundImage: 'url('+props.upload_images+'/'+photos_data[(i*3)+1]+')'}}>
                        </div>
                    }

                    { (i*3)+2 < photos_data.length &&
                        <div className="carousel-image-desktop" style={{backgroundImage: 'url('+props.upload_images+'/'+photos_data[(i*3)+2]+')'}}>
                        </div>
                    }
                </Carousel.Item>
            );
        }
    }

    // Photos glaslook
    const [photos_glaslook_data, photos_glaslook_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(props.ip_address+'/backend/autoglaslimburg/api/photos_glaslook');
                // <str> image

                photos_glaslook_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    let carousel_glaslook_mobile = [];
    let carousel_glaslook_desktop = [];

    if (photos_glaslook_data.length !== 0)
    {
        // Tablet
        for (let i = 0; i < Math.ceil(photos_glaslook_data.length/2); i++)
        {
            carousel_glaslook_mobile.push(
                <Carousel.Item className='slide' key={i}>
                    <div className="carousel-image-mobile" style={{backgroundImage: 'url('+props.upload_images+'/'+photos_glaslook_data[i*2]+')'}}>
                    </div>

                    { (i*2)+1 < photos_glaslook_data.length &&
                        <div className="carousel-image-mobile" style={{backgroundImage: 'url('+props.upload_images+'/'+photos_glaslook_data[(i*2)+1]+')'}}>
                        </div>
                    }
                </Carousel.Item>
            );
        }

        // Desktop
        for (let i = 0; i < Math.ceil(photos_glaslook_data.length/3); i++)
        {
            carousel_glaslook_desktop.push(
                <Carousel.Item className='slide' key={i}>
                    <div className="carousel-image-desktop" style={{backgroundImage: 'url('+props.upload_images+'/'+photos_glaslook_data[i*3]+')'}}>
                    </div>

                    { (i*3)+1 < photos_glaslook_data.length &&
                        <div className="carousel-image-desktop" style={{backgroundImage: 'url('+props.upload_images+'/'+photos_glaslook_data[(i*3)+1]+')'}}>
                        </div>
                    }

                    { (i*3)+2 < photos_glaslook_data.length &&
                        <div className="carousel-image-desktop" style={{backgroundImage: 'url('+props.upload_images+'/'+photos_glaslook_data[(i*3)+2]+')'}}>
                        </div>
                    }
                </Carousel.Item>
            );
        }
    }

    return (
        <React.Fragment>
            <section className="panel-section-single" style={{paddingTop: '48px'}} id="onze-voordelen">
                <div className="container">
                    <div className="row">
                        <ElementPanelHeader
                            title={props.translations['content_advantages_title']}
                            text={props.translations['content_advantages_text']} />
                    </div>
                    <div className="row">
                        <ElementPanelContentContainIcon
                            title={props.translations['content_advantages_borrow_title']}
                            text={props.translations['content_advantages_borrow_text']}
                            image="/image/panel_advantages_borrow.jpg" />

                        <ElementPanelContentContainIcon
                            title={props.translations['content_advantages_star_title']}
                            text={props.translations['content_advantages_star_text']}
                            image="/image/panel_advantages_star.jpg" />
                    </div>
                    <div className="row">
                        <ElementPanelContentContainIcon
                            title={props.translations['content_advantages_warranty_title']}
                            text={props.translations['content_advantages_warranty_text']}
                            image="/image/panel_advantages_warranty.jpg" />

                        <ElementPanelContentContainIcon
                            title={props.translations['content_advantages_since2004_title']}
                            text={props.translations['content_advantages_since2004_text']}
                            image="/image/panel_advantages_since2004.jpg" />
                    </div>
                    <div className="row">
                        <ElementPanelContentContainIcon
                            title={props.translations['content_advantages_cheaper_title']}
                            text={props.translations['content_advantages_cheaper_text']}
                            image="/image/panel_advantages_cheaper.jpg" />

                        <ElementPanelContentContainIcon
                            title={props.translations['content_advantages_multidisciplinary_title']}
                            text={props.translations['content_advantages_multidisciplinary_text']}
                            image="/image/panel_advantages_multidisciplinary.jpg" />
                    </div>
                    <div className="row">
                        <ElementPanelContentContainIcon
                            title={props.translations['content_advantages_123ruit_title']}
                            text={props.translations['content_advantages_123ruit_text']}
                            image="/image/panel_advantages_123ruit.jpg" />

                        <ElementPanelContentContainIcon
                            title={props.translations['content_advantages_age_title']}
                            text={props.translations['content_advantages_age_text']}
                            image="/image/panel_advantages_age.jpg" />
                    </div>
                </div>
            </section>

            <section className="panel-section-single" style={{paddingTop: '48px'}} id="onze-voordelen">
                <div className="container">
                    <div className="row">
                        <ElementPanelHeader
                            title={props.translations['content_labels_title']}
                            text="" />
                    </div>
                    <div className="row">
                        <ElementPanelLabel
                            title="BOVAG"
                            image="/image/element_logo_bovag.jpg" />

                        <ElementPanelLabel
                            title="GroenGedaan!"
                            image="/image/element_logo_groengedaan.jpg" />

                        <ElementPanelLabel
                            title="Glasgarant"
                            image="/image/element_logo_glasgarant.jpg" />

                        <ElementPanelLabel
                            title="Qualified"
                            image="/image/element_logo_qualified.jpg" />
                    </div>
                </div>
            </section>
            
            <section className="panel-section-single-wide">
                <div className="container">
                    <div className="row">
                        <ElementHeaderLarge
                            text={props.translations['content_process_title']} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            { content_data !== undefined &&
                                <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content_data[0])}}></p>
                            }
                        </div>
                        <div className="col-12 col-md-6">
                            <img src="/image/element_recover.jpg" alt={props.translations['content_process_text']} width="100%" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="panel-section-single" style={{paddingTop: '0px', paddingBottom: '0px'}}>
                <div className="d-block d-lg-none">
                    <Carousel>
                        {carousel_mobile}
                    </Carousel>
                </div>
                <div className="d-none d-lg-block">
                    <Carousel>
                        {carousel_desktop}
                    </Carousel>
                </div>
            </section>

            <section className="panel-section-single-wide" id="glaslook">
                <div className="container">
                    <div className="row">
                        <ElementHeaderLarge
                            text={props.translations['content_glaslook_title']} />
                    </div>
                    <div className="row">
                        <div className="col-12">
                            { content_data !== undefined &&
                                <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content_data[3])}}></p>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section className="panel-section-single" style={{paddingTop: '0px'}}>
                <div className="d-block d-lg-none">
                    <Carousel>
                        {carousel_glaslook_mobile}
                    </Carousel>
                </div>
                <div className="d-none d-lg-block">
                    <Carousel>
                        {carousel_glaslook_desktop}
                    </Carousel>
                </div>
            </section>

            <section className="panel-section-single" id="diagram" style={{paddingTop: '0px'}}>
                <div className="container">
                    <div className="row">
                        <ElementPanelContentContain
                            title={props.translations['content_diagram_title']}
                            text={props.translations['content_diagram_text']}
                            image="/image/panel_diagram.jpg" />

                        <ElementPanelContentContain
                            title={props.translations['content_waitingroom_title']}
                            text={props.translations['content_waitingroom_text']}
                            image="/image/panel_waitingroom.jpg" />
                    </div>
                </div>
            </section>

            <section className="panel-section-single" id="navigeer-naar" style={{paddingTop: '0px', paddingBottom: '72px'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="panel-content-column">
                                <ElementHeader
                                    text={props.translations['content_navigateto_title']} />

                                <img src="/image/panel_navigate.jpg" className="panel-content-contact-navigate" alt={props.translations['content_navigateto_title']} width="100%" />
                                <p style={{'paddingBottom': '24px'}}>{props.translations['contact_navigationhelp']}</p>

                                <div className="d-block d-lg-none">
                                    <iframe style={{borderRadius: '6px', marginBottom: '24px'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2514.2571549542354!2d5.871495151864997!3d50.9374571599331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0bf47e4cd4ebd%3A0xa4aac131f86d25f0!2sAutoglas+Limburg!5e0!3m2!1snl!2sus!4v1453946193355" width="100%" height="250px" frameBorder="0" allowFullScreen="allowfullscreen" data-ruffle-polyfilled="" title="Google Maps navigatie mobiel"></iframe>
                                </div>

                                { content_data !== undefined &&
                                    <React.Fragment>
                                        <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content_data[1])}}></p>

                                        <p><a href="https://www.facebook.com/autoglaslimburg" target="_blank" rel="noreferrer" className="body-link">{props.translations['contact_facebook']}</a><span className="panel-icon-thumbsup"></span></p>

                                        <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content_data[2])}}></p>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        <div className="col-6 d-none d-lg-block">
                            <div className="panel-content-column-sidemap">
                                <iframe style={{borderTopRightRadius: '24px', borderBottomRightRadius: '24px'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2514.2571549542354!2d5.871495151864997!3d50.9374571599331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0bf47e4cd4ebd%3A0xa4aac131f86d25f0!2sAutoglas+Limburg!5e0!3m2!1snl!2sus!4v1453946193355" width="100%" height="100%" frameBorder="0" allowFullScreen="allowfullscreen" data-ruffle-polyfilled="" title="Google Maps navigatie desktop"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

// Props: pages, translations
function SectionBanner(props)
{
    return (
        <ElementBanner
            poster="/video/banner_autoglaslimburg.jpg"
            video="/video/banner_autoglaslimburg.m4v"
            banner="banner-autoglaslimburg"
            title={props.translations['banner_title']}
            text={props.translations['banner_text']}
            buttons={[{
                type: 'hash',
                to: 'onze-voordelen',
                class: 'banner-button-secondary',
                text: props.translations['banner_button1']
            },{
                type: 'hash',
                to: 'contact',
                class: 'banner-button-primary',
                text: props.translations['banner_button2']
            }]} 
            translations={props.translations} />
    );
}

// Props: debug, ip_address, translations
function SectionDisclaimer(props)
{
    // Content disclaimer
    const [content_data, content_set_data] = useState(undefined);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: ['disclaimer']
                };
                
                const response = await axios.get(props.ip_address+'/backend/autoglaslimburg/api/content', {params});
                // <str> content text

                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <section className="panel-section-single">
            <div className="container">
                <ElementHeader
                    text={props.translations['disclaimer_title']} />

                { content_data !== undefined &&
                    <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                }

                <ElementContentLink
                    to="/"
                    text={props.translations['navigation_to_home']} />
            </div>
        </section>
    );
}

// Props: translations
function SectionFooter(props)
{
    return (
        <section className="footer-section">
            <div className="container">
                <div className="row">
                    <div className="col">
                        { /* XS, SM, MD */ }
                        <div className="d-block d-lg-none">
                            <div>
                                <ElementFooterLink
                                    to='/'
                                    text={props.translations['navigation_home']} />
                                <ElementFooterLink
                                    to="/vrijwaring"
                                    text={props.translations['navigation_disclaimer']} />
                                <ElementFooterLink
                                    to="/privacyverklaring"
                                    text={props.translations['navigation_privacy']} />
                            </div>
                        </div>

                        { /* LG, XL */ }
                        <div className="d-none d-lg-block">
                            <div className="footer-spacing">
                                <div>
                                    <ElementFooterLink
                                        to='/'
                                        text={props.translations['navigation_home']} />
                                    <ElementFooterLink
                                        to="/vrijwaring"
                                        text={props.translations['navigation_disclaimer']} />
                                    <ElementFooterLink
                                        to="/privacyverklaring"
                                        text={props.translations['navigation_privacy']} />
                                </div>
                            </div>
                        </div>
                        
                        <hr className="footer-line" />

                        <div className="footer-spacing">
                            <span className="footer-text">
                                Inhoud ©{new Date().getFullYear()} Autoglas Limburg. KVK 14087153. Platform ©{new Date().getFullYear()} <a className="footer-link-alt" href="https://thissen.ai/nl-NL" target="_blank" rel="noreferrer">Thissen AI®</a>. Deze website is GDPR-compliant en maakt geen gebruik van tracking cookies. Alle rechten voorbehouden. 
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: debug, translations, ip_address
function SectionForm(props)
{
    const [form_name, form_set_name] = useState('');
    const [form_phone, form_set_phone] = useState('');
    const [form_email, form_set_email] = useState('');
    const [form_dropdown, form_set_dropdown] = useState('');
    const [form_licenseplate, form_set_licenseplate] = useState('');
    const [form_description, form_set_description] = useState('');
    const [form_terms, form_set_terms] = useState(false);
    const [form_accept, form_set_accept] = useState(false);
    const [form_sent, form_set_sent] = useState(false);
    const [form_loading, form_set_loading] = useState(false);
    const [form_error, form_set_error] = useState(false);

    // Update default dropdown when translation has been loaded
    useEffect(() => {
        form_set_dropdown(props.translations['contactform_dropdown1']);
    }, [props.translations]);

    const handleSubmit = (event) => {
        form_set_loading(true);
        
        const post = async () => {
            try
            {
                let form_phone_corrected = form_phone;
                if (form_phone_corrected === '')
                    form_phone_corrected = '-';

                let form_description_corrected = form_description;
                if (form_description_corrected === '')
                    form_description_corrected = '-';

                const data = {
                    name: form_name,
                    phone: form_phone_corrected,
                    email: form_email,
                    dropdown: form_dropdown,
                    licenseplate: form_licenseplate,
                    description: form_description_corrected,
                    terms: form_terms,
                    accept: form_accept
                }

                await axios.post(props.ip_address+'/backend/autoglaslimburg/api/form_contact', data);

                form_set_sent(true);
                form_set_loading(false);
            }
            
            catch (error)
            {
                console.log(error);
                form_set_error(true);
                form_set_loading(false);
            }
        }

        post();

        event.preventDefault();
    }

    let form_terms_class = 'form-checkbox';
    if (form_terms === true)
        form_terms_class = 'form-checkbox-active';

    return (
        <section className="form-section" id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-12 d-block d-md-none">
                        <div className="form-header">
                            <ElementHeader text={props.translations['contactform_title']} />
                            <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: props.translations['contactform_text']}}></p>
                        </div>
                    </div>
                    <div className="col-12 d-none d-md-block">
                        <div className="form-header">
                            <ElementHeader text={props.translations['contactform_title']} />
                            <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: props.translations['contactform_text']}}></p>
                        </div>
                    </div>
                </div>

                { form_loading === false && form_sent === false && form_error === false &&
                    <form onSubmit={event => handleSubmit(event)}>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_name"
                                    type="text"
                                    maxLength="128"
                                    value={form_name}
                                    autoComplete="off"
                                    onChange={event => form_set_name(event.target.value)}
                                    placeholder={props.translations['contactform_name']+' *'}
                                    required />
                            </div>
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_phone"
                                    type="text"
                                    maxLength="128"
                                    value={form_phone}
                                    autoComplete="off"
                                    onChange={event => form_set_phone(event.target.value)}
                                    placeholder={props.translations['contactform_phone']} />
                            </div>
                            <div className="col-12 col-md-4">
                                <input
                                    className="form-singleline"
                                    name="form_email"
                                    type="email"
                                    maxLength="128"
                                    value={form_email}
                                    autoComplete="off"
                                    onChange={event => form_set_email(event.target.value)}
                                    placeholder={props.translations['contactform_email']+' *'} 
                                    required />
                            </div>
                            <div className="col-12 col-md-6">
                                <select
                                    className="form-singleline-dropdown"
                                    name="form_dropdown"
                                    value={form_dropdown}
                                    onChange={event => form_set_dropdown(event.target.value)} >
                                    
                                    <option value={props.translations['contactform_dropdown1']}>{props.translations['contactform_dropdown1']}</option>
                                    <option value={props.translations['contactform_dropdown2']}>{props.translations['contactform_dropdown2']}</option>
                                    <option value={props.translations['contactform_dropdown3']}>{props.translations['contactform_dropdown3']}</option>
                                    <option value={props.translations['contactform_dropdown4']}>{props.translations['contactform_dropdown4']}</option>
                                    <option value={props.translations['contactform_dropdown5']}>{props.translations['contactform_dropdown5']}</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-6">
                                <input
                                    className="form-singleline"
                                    name="form_licenseplate"
                                    type="text"
                                    maxLength="128"
                                    value={form_licenseplate}
                                    autoComplete="off"
                                    onChange={event => form_set_licenseplate(event.target.value)}
                                    placeholder={props.translations['contactform_licenseplate']+' *'}
                                    required />
                            </div>
                            <div className="col-12">
                                <textarea
                                    className="form-multiline"
                                    name="form_description"
                                    maxLength="4096"
                                    value={form_description}
                                    onChange={event => form_set_description(event.target.value)}
                                    placeholder={props.translations['contactform_description']} />
                            </div>
                            <div className="col-12">
                                <div className="form-checkbox-container">
                                    <label className={form_terms_class}>
                                        <input
                                            name="form_terms"
                                            type="checkbox"
                                            checked={form_terms}
                                            onChange={event => form_set_terms(event.target.checked)}
                                            required />
                                        <span className="form-text">{props.translations['contactform_terms']} <a href="/privacyverklaring" className="body-link" target="_blank" rel="noopener noreferrer">{props.translations['contactform_privacy']}</a>. *</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-12">
                                <input
                                    className="form-accept"
                                    name="form_accept"
                                    type="checkbox"
                                    checked={form_accept}
                                    onChange={event => form_set_accept(event.target.checked)} />
                            </div>
                            <div className="col-12">
                                <button onClick = {() => {}} className="form-send"><span>{props.translations['contactform_send']}</span></button>
                            </div>
                        </div>
                    </form>
                }

                { form_loading === true &&
                    <div className="form-loading"></div>
                }

                { form_loading === false && form_error === true &&
                    <div className="form-error">{props.translations['contactform_error']}</div>
                }

                { form_loading === false && form_error === false && form_sent === true &&
                    <div className="form-thankyou">{props.translations['contactform_complete']}</div>
                }
            </div>
        </section>
    );

    /*
    let form = '';
    if (form_sent === false)
    {
        if (form_loading === false)
        {
            form = (
                <form onSubmit={event => handleSubmit(event)}>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <input
                                className="form-singleline"
                                name="form_name"
                                type="text"
                                maxLength="128"
                                value={form_name}
                                autoComplete="off"
                                onChange={event => form_set_name(event.target.value)}
                                placeholder={props.translations['contactform_name']+' *'}
                                required />
                        </div>
                        <div className="col-12 col-md-4">
                            <input
                                className="form-singleline"
                                name="form_phone"
                                type="text"
                                maxLength="128"
                                value={form_phone}
                                autoComplete="off"
                                onChange={event => form_set_phone(event.target.value)}
                                placeholder={props.translations['contactform_phone']} />
                        </div>
                        <div className="col-12 col-md-4">
                            <input
                                className="form-singleline"
                                name="form_email"
                                type="email"
                                maxLength="128"
                                value={form_email}
                                autoComplete="off"
                                onChange={event => form_set_email(event.target.value)}
                                placeholder={props.translations['contactform_email']+' *'} 
                                required />
                        </div>
                        <div className="col-12 col-md-6">
                            <select
                                className="form-singleline-dropdown"
                                name="form_dropdown"
                                value={form_dropdown}
                                onChange={event => form_set_dropdown(event.target.value)} >
                                
                                <option value={props.translations['contactform_dropdown1']}>{props.translations['contactform_dropdown1']}</option>
                                <option value={props.translations['contactform_dropdown2']}>{props.translations['contactform_dropdown2']}</option>
                                <option value={props.translations['contactform_dropdown3']}>{props.translations['contactform_dropdown3']}</option>
                                <option value={props.translations['contactform_dropdown4']}>{props.translations['contactform_dropdown4']}</option>
                                <option value={props.translations['contactform_dropdown5']}>{props.translations['contactform_dropdown5']}</option>
                            </select>
                        </div>
                        <div className="col-12 col-md-6">
                            <input
                                className="form-singleline"
                                name="form_licenseplate"
                                type="text"
                                maxLength="128"
                                value={form_licenseplate}
                                autoComplete="off"
                                onChange={event => form_set_licenseplate(event.target.value)}
                                placeholder={props.translations['contactform_licenseplate']+' *'}
                                required />
                        </div>
                        <div className="col-12">
                            <textarea
                                className="form-multiline"
                                name="form_description"
                                maxLength="4096"
                                value={form_description}
                                onChange={event => form_set_description(event.target.value)}
                                placeholder={props.translations['contactform_description']} />
                        </div>
                        <div className="col-12">
                            <div className="form-checkbox-container">
                                <label className={form_terms_class}>
                                    <input
                                        name="form_terms"
                                        type="checkbox"
                                        checked={form_terms}
                                        onChange={event => form_set_terms(event.target.checked)}
                                        required />
                                    <span className="form-text">{props.translations['contactform_terms']} <a href="/privacyverklaring" className="body-link" target="_blank" rel="noopener noreferrer">{props.translations['contactform_privacy']}</a>. *</span>
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <input
                                className="form-accept"
                                name="form_accept"
                                type="checkbox"
                                checked={form_accept}
                                onChange={event => form_set_accept(event.target.checked)} />
                        </div>
                        <div className="col-12">
                            <button onClick = {() => {}} className="form-send"><span>{props.translations['contactform_send']}</span></button>
                        </div>
                    </div>
                </form>
            );
        }
    }
    else
    {
        form = (
            <div className="form-thankyou">
                {props.translations['contactform_complete']}
            </div>
        );
    }

    let loading = '';
    if (form_loading === true)
    {
        loading = (
            <div className="form-loading"></div>
        );
    }

    return (
        <section className="form-section" id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-12 d-block d-md-none">
                        <div className="form-header">
                            <ElementHeader text={props.translations['contactform_title']} />
                            <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: props.translations['contactform_text']}}></p>
                        </div>
                    </div>
                    <div className="col-12 d-none d-md-block">
                        <div className="form-header">
                            <ElementHeader text={props.translations['contactform_title']} />
                            <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: props.translations['contactform_text']}}></p>
                        </div>
                    </div>
                </div>

                {loading}
                {form}
            </div>
        </section>
    );
    */
}

// Props: options, translations
function SectionNavigation(props)
{
    // Expand (mobile)
    const [expanded, setExpanded] = useState(false);

    return (
        <React.Fragment>
            <section className="navbar-section fixed-top">
                <div className="container-sm">
                    <Navbar expanded={expanded} expand="lg" variant="light" className="navbar-container">
                        { /* Logo */ }
                        <div className="navbar-title-container">
                            <Link to="/" className="navbar-title" onClick={() => setExpanded(false)}></Link>
                        </div>

                        <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="$navbarScroll" onClick={() => setExpanded(expanded ? false : 'expanded')} />
                        <Navbar.Collapse id="navbarScroll" className="navbar-justify-right">
                            <Nav>
                                <div>
                                    <HashLink
                                        smooth
                                        to="#diagram"
                                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}
                                        className="navbar-item"
                                        onClick={() => setExpanded(false)}>
                                        <span>{props.translations['navigation_diagram']}</span>
                                    </HashLink>
                                </div>

                                <div>
                                    <HashLink
                                        smooth
                                        to="#glaslook"
                                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}
                                        className="navbar-item"
                                        onClick={() => setExpanded(false)}>
                                        <span>{props.translations['navigation_glaslook']}</span>
                                    </HashLink>
                                </div>

                                <div>
                                    <HashLink
                                        smooth
                                        to="#navigeer-naar"
                                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}
                                        className="navbar-item"
                                        onClick={() => setExpanded(false)}>
                                        <span>{props.translations['navigation_navigateto']}</span>
                                    </HashLink>
                                </div>

                                <div>
                                    <HashLink
                                        smooth
                                        to="#contact"
                                        scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}
                                        className="navbar-item"
                                        onClick={() => setExpanded(false)}>
                                        <span>{props.translations['navigation_contact']}</span>
                                    </HashLink>
                                </div>

                                <div className="d-none d-lg-block">
                                    <iframe src="https://www.klantenvertellen.nl/retrieve-widget.html?color=white&amp;allowTransparency=true&amp;button=false&amp;lang=nl&amp;tenantId=99&amp;locationId=1060658" width="250" height="60" className="navbar-item-reviews" frameBorder="0" title="KlantenVertellen navigatie banner"></iframe>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
                <ElementStatus
                    options={props.options}
                    translations={props.translations} />
            </section>
            <section className="navbar-spacing"></section>
        </React.Fragment>
    );
}

// Props: debug, ip_address, translations
function SectionPrivacy(props)
{
    // Content privacy
    const [content_data, content_set_data] = useState(undefined);
    useEffect(() => {
        const get = async() => {
            try
            {
                let params = {
                    content_keys: ['privacy']
                };
                
                const response = await axios.get(props.ip_address+'/backend/autoglaslimburg/api/content', {params});
                // <str> content text

                content_set_data(response.data);
                
                if (props.debug === true)
                    console.log(response.data);
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [props.debug, props.ip_address]);

    return (
        <section className="panel-section-single">
            <div className="container">
                <ElementHeader
                    text={props.translations['privacy_title']} />

                { content_data !== undefined &&
                    <div className="panel-content-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content_data[0]) }}></div>
                }

                <ElementContentLink
                    to="/"
                    text={props.translations['navigation_to_home']} />
            </div>
        </section>
    );
}

export { SectionBanner, SectionContent, SectionDisclaimer, SectionFooter, SectionForm, SectionNavigation, SectionPrivacy };